import {Provider} from '@angular/core';
import {LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MAT_LUXON_DATE_FORMATS} from '@angular/material-luxon-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {DateTime} from 'luxon';

export class LimitLuxonDateAdapter extends LuxonDateAdapter {
  getValidDateOrNull(obj: unknown): DateTime | null {
    const date = super.getValidDateOrNull(obj);
    const limitDate = this.createDate(2037, 11, 31);
    if (date && this.compareDate(date, limitDate) > 0) {
      return limitDate;
    }
    return date;
  }
}

export const LUXON_ADAPTER_PROVIDER: Provider[] = [
  {
    provide: DateAdapter,
    useClass: LimitLuxonDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
  },
  {
    provide: MAT_DATE_FORMATS,
    useValue: MAT_LUXON_DATE_FORMATS
  }
];

export const LUXON_ADAPTER_UTC_PROVIDERS: Provider[] = [
  {
    provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
    useValue: {useUtc: true}
  },
  LUXON_ADAPTER_PROVIDER
];
